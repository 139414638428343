import { string } from 'prop-types';
import React, { useMemo } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Content } from '../../common/content';
import './department.scss';

const bem = new BEMHelper({
  name: 'content-department-heading',
});

export const DepartmentHeading = ({
  title,
  slogan,
  assetAlt,
  brochureLink
}) => {
  const { t } = useTranslation();
  const transformed = useMemo(
    () => slogan
      .replace(
        /\*(.*)\*/g,
        (_, highligh) => `<strong class="${bem('highlight').className}">${highligh}</strong>`,
      )
      .replace(
        /<strong .*>(.*)<\/strong>.*$/g,
        (group) => `<span class="${bem('group').className}">${group}</span>`,
      ),
    [slogan],
  );

  const output = title.replace(new RegExp('(Care)'), `<span class=${bem('mark').className}>$1</span>`);

  return (
    <Content {...bem()} observe useOwnSpacing>
      <h1 {...bem('headline')}>
        <span {...bem('title')}>{parse(output)}</span>
        <span {...bem('slogan')}>{parse(transformed)}</span>
      </h1>
      <p {...bem('scroll-indicator')}>
       {brochureLink ? (
  <small className="btnDiv">
    <a className="button button--secondary content-teaser-m__link" href={brochureLink}>
      {assetAlt}
    </a>
  </small>
) : null}
        <small class="scrollDiv">
          <a href="#contents-department" title={t('Click here to scroll to the content section')} {...bem('link')}><span {...bem('text')}>{t('Scroll for more info')}</span></a>
        </small>
        
       
        
      </p>
    </Content>
  );
};

DepartmentHeading.propTypes = {
  title: string.isRequired,
  slogan: string.isRequired,
};