import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { Sitemeta } from '../../components/core/sitemeta';
import { DepartmentHeading } from '../../components/headings/department';
import { entryType, responseType } from '../../types';

export const query = graphql`
  query DepartmentPage(
    $departmentId: [CraftGQL_QueryArgument]!
  ) {
    response: craftgql {
      department: entry(id: $departmentId) {
        title
        ...DepartmentContentsFragment

        ...on CraftGQL_departments_department_Entry {
          color: departmentColor
          slogan: departmentSlogan

          metadata: pageMetadata {
            ...MetadataFragment
          }

          # New fields added:
          assetAlt
          brochureLink {
            id
            slug
            uri
            url
          }
        }
      }
    }
  }
`;


const DepartmentPage = ({ data }) => {
  const { department } = data.response;
  
  console.log("department",department)
  
  const {
    title, slogan, contents, metadata: [metadata], assetAlt, brochureLink
  } = department;
  
  
  
  useEffect(() => {
    const scrollToHash = () => {
      // Check if the URL has a hash (e.g., #section-24474)
      if (window.location.hash) {
        const elementId = window.location.hash.substring(1); // Remove the '#' from the hash
        const element = document.getElementById(elementId);

        if (element) {
          // Scroll to the element, adjusting by -150px for offset
          window.scrollTo({
            top: element.offsetTop - 150, // Offset by 150px
            behavior: 'smooth' // Smooth scrolling
          });
        }
      }
    };

    // Ensure the content has loaded before trying to scroll
    // This ensures the element exists in the DOM
    setTimeout(scrollToHash, 100); // Timeout to ensure the DOM has been updated

    // Optionally, listen for hash changes if the page navigates
    window.addEventListener('hashchange', scrollToHash);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, []); 

  return (
    <>
      <Sitemeta
        title={title}
        {...metadata}
        color={department.color}
      />
      <DepartmentHeading title={title} slogan={slogan} assetAlt={assetAlt} brochureLink={brochureLink?.[0]?.slug}/>
      <div id="contents-department">
        <Renderer contents={contents} mapping={CONTENT_MAPPING} />
      </div>
    </>
  );
};

DepartmentPage.propTypes = {
  ...responseType({
    department: entryType({
      title: string.isRequired,
      color: string.isRequired,
      contents: arrayOf(shape({
        typename: string,
      })).isRequired,
    }).isRequired,
  }).isRequired,
};

export default DepartmentPage;